import { Environment } from "@bu/lib-config";
import { GetProfileApiResponse_v1 } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getProfileByGuidAsyncMock = async (
  profileGuid: string,
  env?: Environment
) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Identity, env);
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/profile/${profileGuid}`,
  };

  return client
    .request<GetProfileApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("getProfileByGuidAsyncMock error.", data.errors);
        throw new Error("Error getting profile.");
      }

      // Return payload.
      return data.payload.profile;
    });
};

