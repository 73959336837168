import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { palette } from "../palette";
import { themeBase } from "./themeBase";

export const themeLight = deepmerge(
  themeBase,
  createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "#layoutAside": {
            backgroundColor: palette.SG300,
            borderRight: `solid ${palette.SG400} 1px`,
          },
          "#layoutHeader": {
            backgroundColor: palette.WH100,
            borderBottom: `solid ${palette.SG400} 1px`,
          },
          "#layoutMain": { backgroundColor: palette.SG200 },
        },
      },
    },
    palette: {
      mode: "light",

      primary: {
        main: palette.B600, // MUI Default: "#1976d2",
        light: palette.B500,
        dark: palette.B700,
        contrastText: palette.WH100,
      },
      secondary: {
        main: palette.SG600, // MUI Default: "#9c27b0",
        light: palette.SG500,
        dark: palette.SG700,
        contrastText: palette.WH100,
      },
      error: {
        main: "#d32f2f",
        light: "#ef5350",
        dark: "#c62828",
        contrastText: palette.WH100,
      },
      warning: {
        main: "#ed6c02",
        light: "#ff9800",
        dark: "#e65100",
        contrastText: palette.WH100,
      },
      info: {
        main: "#0288d1",
        light: "#03a9f4",
        dark: "#01579b",
        contrastText: palette.WH100,
      },
      success: {
        main: "#2e7d32",
        light: "#4caf50",
        dark: "#1b5e20",
        contrastText: palette.WH100,
      },

      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.6)",
        disabled: "rgba(0, 0, 0, 0.38)",
      },
      divider: "rgba(0, 0, 0, 0.12)",
      background: {
        default: "#F2F5FA",
        paper: palette.WH100,
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
    },
  })
);
