import { GetSlidesByContentGuidApiResponse_v1 } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getSlidesByContentGuidAsync = async (contentGuid: string) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/slide/vibez/${contentGuid}`,
  };

  return client
    .request<GetSlidesByContentGuidApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetSlidesByContentGuidAsync error.", data.errors);
        throw new Error("Error getting slides.");
      }

      // Return payload.
      return data.payload.items;
    });
};
