import { getTracksByGuidListAsync } from "@bu/lib-services";
import { TrackDto_v1 } from "@bu/lib-types/music";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useTrackListQuery = (trackGuidList: string[]) => {
  const queryClient = useQueryClient();
  const queryKey = ["trackList", trackGuidList];

  const query = useQuery<TrackDto_v1[]>({
    queryKey: queryKey,
    queryFn: () => {
      // Filter out any tracks that are already in the cache.
      const newGuids = trackGuidList.filter(
        (guid) => !queryClient.getQueryData(["track", guid])
      );
      // Fetch the new tracks and cache them.
      return getTracksByGuidListAsync(newGuids).then((tracks) => {
        tracks.forEach((track) => {
          queryClient.setQueryData(["track", track.guid], track);
        });

        // Return the full list of tracks.
        return trackGuidList.map((guid) =>
          queryClient.getQueryData(["track", guid])
        ) as TrackDto_v1[];
      });
    },
    enabled: trackGuidList.length > 0,
    staleTime: 0,
  });

  return {
    tracks: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
