import { createTheme } from "@mui/material";
import { palette } from "../palette";

export const themeBase = createTheme({
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: palette.B600,
          opacity: 0.6,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
      styleOverrides: {
        root: {
          display: "flex",
          gap: "1rem",
          padding: "1rem",
        },
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: 2,
        width: "100%",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: {
          color: palette.B600,
          fontSize: "16px",
        },
      },
    },
  },
});
