import {
  BaseUserDto_v1,
  InsertUserApiResponse_v1,
  InsertUserRequest_v1,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

/**
 * POST a user to the Identity server.
 * @param user The user to insert.
 * @returns A UserDto object.
 */
export const insertUserAsync = async (user: BaseUserDto_v1) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks_read",
  ]);
  const config: AxiosRequestConfig<InsertUserRequest_v1> = {
    method: "POST",
    url: `/api/v1/user`,
    data: {
      user: user,
    },
  };

  return client
    .request<InsertUserApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("InsertUserAsync error.", data.errors);
        throw new Error("Error creating user.");
      }

      // Return payload.
      return data.payload.userGuid;
    });
};
