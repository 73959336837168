import { SilentRequest } from "@azure/msal-browser";
import { MsalSingleton } from "@bu/lib-auth";
import { Environment, getConfig } from "@bu/lib-config";
import axios, { CreateAxiosDefaults } from "axios";

export enum ApiTypes {
  Identity,
  LegacyApi,
  Music,
}

const getAccessTokenAsync = async (scopes: string[]) => {
  const instance = MsalSingleton.getInstance();
  const request: SilentRequest = {
    scopes: scopes,
  };

  let activeAccount = instance.getActiveAccount();
  const allAccounts = instance.getAllAccounts();
  if (!activeAccount && allAccounts?.length) {
    activeAccount = allAccounts[0];
    instance.setActiveAccount(activeAccount);
    console.log("Setting active account manually", activeAccount);
  }

  if (activeAccount) {
    const token = await instance.acquireTokenSilent(request);
    return token.accessToken;
  }

  console.log("No active account found");
  return "";
};

const getClientAsync = async (
  apiType: ApiTypes,
  addAuth: boolean,
  scopes: string[],
  env?: Environment
) => {
  const config = getConfig(env);
  let baseUrl = "";
  let scopeUrlPrefix = "";

  switch (apiType) {
    case ApiTypes.Identity:
      baseUrl = config.API_BASEURL_IDENTITY;
      scopeUrlPrefix = `${config.AZURE_B2C_BASEURL_SCOPE}/identity`;
      break;

    case ApiTypes.LegacyApi:
      baseUrl = config.API_BASEURL_LEGACY;
      scopeUrlPrefix = `${config.AZURE_B2C_BASEURL_SCOPE}/legacy`;
      break;

    case ApiTypes.Music:
      baseUrl = config.API_BASEURL_MUSIC;
      scopeUrlPrefix = `${config.AZURE_B2C_BASEURL_SCOPE}/music`;
      break;
  }

  if (!baseUrl.endsWith("/")) {
    baseUrl = `${baseUrl}/`;
  }

  const axiosConfig: CreateAxiosDefaults = {
    baseURL: baseUrl,
    headers: {
      "Content-type": "application/json",
    },
  };

  if (addAuth) {
    // Build scopes here.
    const scopeURLs = scopes.map((scope) => {
      return `${scopeUrlPrefix}/${scope}`;
    });

    const accessToken = await getAccessTokenAsync(scopeURLs);
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return axios.create(axiosConfig);
};

export const apiClientFactory = {
  buildAsync: (apiType: ApiTypes, env?: Environment) => {
    return getClientAsync(apiType, false, [], env);
  },
  buildWithAuthAsync: async (apiType: ApiTypes, scopes: string[]) => {
    return getClientAsync(apiType, true, scopes);
  },
};
