import { resolveSlideAsync } from "@bu/lib-services";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useResolveSlideQuery = (slideGuid: string) => {
  const queryKey = ["slide", slideGuid];

  const query = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam }) => resolveSlideAsync(slideGuid, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage) return 1;

      const nextPage = Math.min(
        lastPage.paging.pageCount || 1,
        lastPage.paging.pageNumber + 1
      );
      return nextPage;
    },
  });

  return {
    slideItems: query.data?.pages,
    isSlideItemsLoading: query.isLoading,
    isSlideItemsNextPageLoading: query.isFetchingNextPage,
    isSlideItemsError: query.isError,

    fetchNextPage: query.fetchNextPage,
    hasNextPage: query.hasNextPage,
  };
};
