import {
  BaseReleaseDto_v1,
  InsertReleaseApiResponse_v1,
  InsertReleaseRequest_v1,
} from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const insertReleaseAsync = async (release: BaseReleaseDto_v1) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Music, [
    "tasks_read",
  ]);
  const config: AxiosRequestConfig<InsertReleaseRequest_v1> = {
    data: {
      release,
    },
    method: "POST",
    url: `/api/v1/release`,
  };

  return client
    .request<InsertReleaseApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("InsertReleaseAsync error.", data.errors);
        throw new Error("Error inserting release.");
      }

      // Return payload.
      return data.payload.releaseGuid;
    });
};
