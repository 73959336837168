import { getContentByFilter } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useContentQuery = (contentText: string) => {
  const queryKey = [contentText];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getContentByFilter(contentText);
    },
  });

  return {
    content: query.data,

    isContentLoading: query.isLoading,
    isContentError: query.isError,
  };
};
