import { GetProfileApiResponse_v1 } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getProfileByGuidNoAuthAsync = async (profileGuid: string) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Identity);
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/profile/public/${profileGuid}`,
  };

  return client
    .request<GetProfileApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetProfileByGuidAsync error.", data.errors);
        throw new Error("Error getting profile.");
      }

      // Return payload.
      return data.payload.profile;
    });
};
