import { getSlidesByContentGuidAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useContentSlidesQuery = (contentGuid: string) => {
  const queryKey = ["contentSlides", contentGuid];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => getSlidesByContentGuidAsync(contentGuid),
  });

  return {
    slideItems: query.data,
    isSlideItemsLoading: query.isLoading,
    isSlideItemsError: query.isError,
  };
};
