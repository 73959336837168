import { ResolveSlideBySlideGuidApiResponse_v1 } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const resolveSlideAsync = async (slideGuid: string, page: number) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/slide/resolve/${slideGuid}?pageNo=${page}`,
  };

  return client
    .request<ResolveSlideBySlideGuidApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("ResolveSlideAsync error.", data.errors);
        throw new Error("Error resolving slide.");
      }

      // Return payload.
      return data.payload;
    });
};
