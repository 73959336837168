import { getContentAsync } from "@bu/lib-services";
import { ContentEnum } from "@bu/lib-types/music";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { QueryContext } from "../../contexts";

type AVAILABLE_CONTENTS = keyof typeof ContentEnum;

export const useGetContentQuery = (content: AVAILABLE_CONTENTS) => {
  const { env } = useContext(QueryContext);
  const { data, isLoading } = useQuery({
    queryFn: (_) => getContentAsync(ContentEnum[content], env),
    queryKey: ["content", content, env],
  });

  return { contents: data, isContentLoading: isLoading };
};

