import { getProfileListByGuidsAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useGetProfileListByGuidsQuery = (profileGuids: string[]) => {
  const query = useQuery({
    queryKey: ["userProfiles", profileGuids],
    queryFn: () => getProfileListByGuidsAsync(profileGuids),
    enabled: profileGuids.length > 0,
  });

  return {
    profiles: query.data,
    isLoading: query.isLoading,
    error: query.error,
  };
};

