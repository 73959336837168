import {
  UpdateUserApiResponse_v1,
  UpdateUserRequest_v1,
  UserDto_v1,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const updateUserAsync = async (user: UserDto_v1) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks_read",
  ]);
  const config: AxiosRequestConfig<UpdateUserRequest_v1> = {
    data: { user: user },
    method: "PATCH",
    url: `/api/v1/user`,
  };

  return client
    .request<UpdateUserApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("UpdateUserAsync error.", data.errors);
        throw new Error("Error updating user.");
      }
      return data.isSuccess;
    });
};
