import {
  deleteReleaseByGuidAsync,
  getReleaseByGuidNoAuthAsync,
  insertReleaseAsync,
  updateReleaseAsync,
} from "@bu/lib-services";
import { BaseReleaseDto_v1, ReleaseDto_v1 } from "@bu/lib-types/music";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useReleaseNoAuthQuery = (releaseGuid?: string) => {
  const queryKey = ["release", releaseGuid];
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      if (!releaseGuid) throw new Error("BaseRelease must be inserted first");
      return getReleaseByGuidNoAuthAsync(releaseGuid);
    },
    enabled: !!releaseGuid,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (baseRelease: BaseReleaseDto_v1) => {
      if (releaseGuid) throw new Error("Release cannot be inserted again");
      return insertReleaseAsync(baseRelease);
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (release: ReleaseDto_v1) => {
      if (!releaseGuid) throw new Error("BaseRelease must be inserted first");

      return updateReleaseAsync(release);
    },
    onMutate(variables) {
      queryClient.setQueryData<ReleaseDto_v1>(queryKey, variables);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: () => {
      if (!releaseGuid) throw new Error("BaseRelease must be inserted first");
      return deleteReleaseByGuidAsync(releaseGuid);
    },
    onSuccess: () => {
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    release: query.data as ReleaseDto_v1,

    insertReleaseAsync: insertMutation.mutateAsync,
    updateReleaseAsync: updateMutation.mutateAsync,
    deleteReleaseAsync: deleteMutation.mutateAsync,

    isReleaseLoading: query.isLoading,
    isReleaseError: query.isError,
  };
};
