import { getSlidesPaginatedAsync } from "@bu/lib-services";
import { GetSlidesAsyncProps } from "@bu/lib-types/music";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { QueryContext } from "../../contexts";

type UseMobileSlidesQueryPaginatedProps = GetSlidesAsyncProps & {
  pageNo: number;
  slideGuid: string;
};

export const useMobileSlidesQueryPaginated = (
  props: UseMobileSlidesQueryPaginatedProps
) => {
  const { env } = useContext(QueryContext);

  const query = useQuery({
    queryKey: ["getSlidesInfiniteQuery", props.contentGuid, props.pageNo, env],
    queryFn: () => {
      return getSlidesPaginatedAsync(
        {
          content: props.content,
          contentGuid: props.contentGuid,
          pageNo: props.pageNo,
          slideGuid: props.slideGuid,
        },
        env
      );
    },
    enabled: false,
  });

  return {
    trigger: query.refetch,
  };
};
