import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteProfileAsync,
  getProfilesAsync,
  insertProfileAsync,
  updateProfileAsync,
} from "@bu/lib-services";
import {
  ProfileDto_v1,
  RoleDto_v1,
  SubscriptionDto_v1,
  UserDto_v1,
} from "@bu/lib-types/identity";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useUserProfilesQuery = (userGuid?: string) => {
  const queryKey = ["userProfiles"];
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getProfilesAsync().then((profiles) => {
        profiles.forEach((profile) => {
          queryClient.setQueryData(["profile", profile.guid], profile);
        });
        return profiles;
      });
    },
    enabled: isAuthenticated,
    staleTime: 0,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      user: UserDto_v1;
      role: RoleDto_v1;
      subscription: SubscriptionDto_v1;
      profile: ProfileDto_v1;
    }) =>
      insertProfileAsync(
        mutationArgs.user.guid,
        mutationArgs.role.guid,
        mutationArgs.subscription.guid,
        mutationArgs.profile
      ),
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (profile: ProfileDto_v1) => {
      queryClient.setQueryData(["profile", profile.guid], profile);
      queryClient.setQueryData(
        ["userProfiles"],
        (oldProfiles: ProfileDto_v1[]) => {
          return oldProfiles.map((oldProfile) => {
            if (oldProfile.guid === profile.guid) {
              return profile;
            }
            return oldProfile;
          });
        }
      );
      return updateProfileAsync(profile);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: { profile: ProfileDto_v1; role: RoleDto_v1 }) =>
      deleteProfileAsync(mutationArgs.profile, mutationArgs.role),
  });

  const activeProfile =
    query.data?.find((profile) => profile.isActive) || query.data?.[0];

  const setActiveProfile = (profileGuid: string) => {
    const oldProfile = activeProfile;
    const newProfile = query.data?.find(
      (profile) => profile.guid === profileGuid
    );

    updateMutation
      .mutateAsync({ ...oldProfile, isActive: false } as ProfileDto_v1)
      .then(() => {
        updateMutation.mutateAsync({
          ...newProfile,
          isActive: true,
        } as ProfileDto_v1);
      });
  };

  return {
    profiles: query.data || [],

    insertProfileAsync: insertMutation.mutateAsync,
    updateProfileAsync: updateMutation.mutateAsync,
    deleteProfileAsync: deleteMutation.mutateAsync,

    activeProfile,
    setActiveProfile,

    isUserProfilesLoading: query.isLoading,
    isUserProfilesError: query.isError,
  };
};
