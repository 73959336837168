import { getProfileByGuidAsyncMock } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { QueryContext } from "../../contexts";

export const useProfileByIdQuery = (profileGuid: string) => {
  const { env } = useContext(QueryContext);
  const profileByGuidQuery = useQuery({
    queryFn: async () => getProfileByGuidAsyncMock(profileGuid, env),
    queryKey: ["profileByGuid", profileGuid, env],
  });

  return {
    profile: profileByGuidQuery.data,
    isLoading: profileByGuidQuery.isLoading,
    isFetching: profileByGuidQuery.isFetching,
  };
};

