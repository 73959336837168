import { AppConfiguration } from "./appConfiguration";
import { aksDevelopmentConfig } from "./configs/config.aks.development";
import { aksProductionConfig } from "./configs/config.aks.production";
import { aksSandboxConfig } from "./configs/config.aks.sandbox";
import { localDevelopmentConfig } from "./configs/config.development";
import { Environment } from "./environment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let global: any;
declare global {
  interface Window {
    config: AppConfiguration;
  }
}

export const getConfig = (env?: Environment) => {
  if (env) {
    if (env === Environment.DEVELOPMENT) {
      return aksDevelopmentConfig;
    } else if (env === Environment.SANDBOX) {
      return aksSandboxConfig;
    } else if (env === Environment.LOCAL_DEVELOPMENT) {
      return localDevelopmentConfig;
    } else {
      return aksProductionConfig;
    }
  }

  ("undefined" != typeof global ? global : window).config = {
    ...aksProductionConfig,
    ...process.env,
    ...window.config,
  };

  return ("undefined" != typeof global ? global : window)
    .config as AppConfiguration;
};

