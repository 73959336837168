import { GetProfilesApiResponse_v1 } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getProfilesAsync = async () => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "users_user_read",
  ]);
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/api/v1/profile",
  };

  return client
    .request<GetProfilesApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetProfilesAsync error.", data.errors);
        throw new Error("Error getting profiles.");
      }

      // Return payload.
      return data.payload.profiles;
    });
};
