import { AppConfiguration } from "../appConfiguration";

export const aksProductionConfig: AppConfiguration = {
  ENV_NAME: "aks.production",

  AZURE_AI_DEBUG: "false",
  AZURE_AI_CONNECTIONSTRING:
    "InstrumentationKey=14c34bc5-bea1-4e32-92c8-ae80cdcc7f3f;IngestionEndpoint=https://canadacentral-0.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/",

  AZURE_B2C_CLIENTID: "428de96a-abfe-4e0e-b594-6c1c30b4bf09",
  AZURE_B2C_AUTHORITY: `https://adbeatsuniondev.b2clogin.com/9c958150-a092-43bd-82ec-9beb456a013e/B2C_1_adbeatsuniondev`,
  AZURE_B2C_KNOWNAUTHORITY: "adbeatsuniondev.b2clogin.com",
  AZURE_B2C_BASEURL_SCOPE: "https://adbeatsuniondev.onmicrosoft.com/api",
  AZURE_B2C_REDIRECTURI: "/",
  AZURE_B2C_POSTLOGOUTREDIRECTURI: "/",
  AZURE_B2C_CACHELOCATION: "localStorage",
  AZURE_B2C_STOREAUTHSTATEINCOOKIE: "true",

  API_BASEURL_LEGACY: "https://api.prod.beatsunion.com",
  API_BASEURL_IDENTITY: "https://identity.prod.beatsunion.com",
  API_BASEURL_MUSIC: "https://music.prod.beatsunion.com",

  MFE_MANIFEST_APP_ADMIN: "https://app-admin.prod.beatsunion.com",
  MFE_MANIFEST_APP_ARTIST_PORTAL:
    "https://app-artist-portal.prod.beatsunion.com",
};

