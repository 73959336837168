import {
  SocialLinkDto_v1,
  UpdateSocialLinkApiResponse_v1,
  UpdateSocialLinkRequest_v1,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const updateSocialLinkAsync = async (
  profileGuid: string,
  socialLink: SocialLinkDto_v1
) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks_read",
  ]);
  const config: AxiosRequestConfig<UpdateSocialLinkRequest_v1> = {
    data: {
      profileGuid: profileGuid,
      socialLink: socialLink,
    },
    method: "PATCH",
    url: `/api/v1/socialLink/${socialLink.guid}`,
  };

  return client
    .request<UpdateSocialLinkApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("UpdateSocialLinkAsync error.", data.errors);
        throw new Error("Error updating social link.");
      }

      // Return payload.
      return data.isSuccess;
    });
};
