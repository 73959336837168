import { GetVibesByFilterApiResponse_v1 } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getContentByFilter = async (contentText: string) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/content/${contentText}/`,
  };

  return client
    .request<GetVibesByFilterApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("GetContentByFilterAsync error.", data.errors);
        throw new Error("Error getting content.");
      }

      // Return payload.
      return data.payload;
    });
};
