import { GetSocialLinkByGuidApiResponse_v1 } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getSocialLinkByGuidAsync = async (socialLinkGuid: string) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks_read",
  ]);
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/socialLink/${socialLinkGuid}`,
  };

  return client
    .request<GetSocialLinkByGuidApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetSocialLinkByGuidAsync error.", data.errors);
        throw new Error("Error getting socialLink.");
      }

      // Return payload.
      return data.payload.socialLink;
    });
};
