import { getMobileTrackListAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { QueryContext } from "../../contexts";

export const useMobileTrackListQuery = (guids: string[]) => {
  const { env } = useContext(QueryContext);
  const query = useQuery({
    queryKey: ["getTracksWithFilters", guids, env],
    queryFn: () => getMobileTrackListAsync(guids, env),
    enabled: guids.length > 0,
  });

  return {
    tracks: query.data?.payload.tracks,
    isLoadingTracks: query.isLoading,
    refetchTracks: query.refetch,
  };
};

