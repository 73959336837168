import { getSlidesAsync } from "@bu/lib-services";
import { GetSlidesAsyncProps } from "@bu/lib-types/music";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { QueryContext } from "../../contexts";

export const useMobileGetSlidesQuery = (props: GetSlidesAsyncProps) => {
  const { env } = useContext(QueryContext);

  const initialQuery = useQuery({
    queryKey: ["getSlidesInfiniteQuery", props.contentGuid, env],
    queryFn: () => {
      const args = {
        content: props.content,
        contentGuid: props.contentGuid,
      };
      return getSlidesAsync(args, env);
    },
    enabled: !!props.contentGuid,
  });

  return {
    slides: initialQuery.data,
    isLoadingSlides: initialQuery.isLoading,
  };
};

