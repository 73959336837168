import {
  deleteProfileAsync,
  getProfileByGuidAsync,
  insertProfileAsync,
  updateProfileAsync,
} from "@bu/lib-services";
import {
  BaseProfileDto_v1,
  ProfileDto_v1,
  RoleDto_v1,
} from "@bu/lib-types/identity";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

/**
 * Queries a profile by profileGuid.
 * @param profileGuid (Optional) The profile guid to query. If not provided, the profile will be a baseProfile and must be inserted first.
 */
export const useProfileQuery = (profileGuid?: string) => {
  const queryKey = ["profile", profileGuid];
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!profileGuid) throw new Error("BaseProfile must be inserted first.");
      return getProfileByGuidAsync(profileGuid);
    },
    enabled: !!profileGuid,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      userGuid: string;
      roleGuid: string;
      subscriptionGuid: string;
      baseProfile: BaseProfileDto_v1;
    }) => {
      if (profileGuid) throw new Error("Profile cannot be inserted again.");
      return insertProfileAsync(
        mutationArgs.userGuid,
        mutationArgs.roleGuid,
        mutationArgs.subscriptionGuid,
        mutationArgs.baseProfile
      );
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (profile: ProfileDto_v1) => {
      if (!profileGuid) throw new Error("BaseProfile must be inserted first.");
      return updateProfileAsync(profile);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      profile: ProfileDto_v1;
      role: RoleDto_v1;
    }) => {
      if (!profileGuid) throw new Error("BaseProfile must be inserted first.");
      return deleteProfileAsync(mutationArgs.profile, mutationArgs.role);
    },
    onSuccess: () => {
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    profile: query.data,

    insertProfileAsync: insertMutation.mutateAsync,
    updateProfileAsync: updateMutation.mutateAsync,
    deleteProfileAsync: deleteMutation.mutateAsync,

    isProfileLoading: query.isLoading,
    isProfileError: query.isError,
  };
};
