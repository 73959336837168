import {
  deleteReleaseByGuidAsync,
  getReleaseByGuidAsync,
  insertReleaseAsync,
  updateReleaseAsync,
} from "@bu/lib-services";
import { BaseReleaseDto_v1, ReleaseDto_v1 } from "@bu/lib-types/music";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export const useReleaseQuery = (releaseGuid?: string) => {
  const [_releaseGuid, _setReleaseGuid] = useState(releaseGuid);
  const queryKey = ["release", _releaseGuid];
  const queryClient = useQueryClient();

  // Updates internal GUID state if the GUID changes.
  // useEffect(() => {
  //   if (_releaseGuid === releaseGuid) return;
  //   _setReleaseGuid(releaseGuid);
  // }, [releaseGuid, _releaseGuid]);

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      if (!_releaseGuid) throw new Error("BaseRelease must be inserted first");
      return getReleaseByGuidAsync(_releaseGuid);
    },
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (baseRelease: BaseReleaseDto_v1) => {
      if (_releaseGuid) throw new Error("Release cannot be inserted again");
      return insertReleaseAsync(baseRelease);
    },
    onSuccess: (newReleaseGuid) => {
      _setReleaseGuid(newReleaseGuid);
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (release: ReleaseDto_v1) => {
      if (!_releaseGuid) throw new Error("BaseRelease must be inserted first");

      return updateReleaseAsync(release);
    },
    onMutate(variables) {
      queryClient.setQueryData<ReleaseDto_v1>(queryKey, variables);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: () => {
      if (!_releaseGuid) throw new Error("BaseRelease must be inserted first");
      return deleteReleaseByGuidAsync(_releaseGuid);
    },
    onSuccess: () => {
      _setReleaseGuid(undefined);
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    release: query.data as ReleaseDto_v1,

    insertReleaseAsync: insertMutation.mutateAsync,
    updateReleaseAsync: updateMutation.mutateAsync,
    deleteReleaseAsync: deleteMutation.mutateAsync,

    isReleaseLoading: query.isLoading,
    isReleaseError: query.isError,
  };
};
