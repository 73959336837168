import { DeleteUserApiResponse_v1 } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const deleteUserAsync = async () => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks_read",
  ]);
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/user`,
  };

  return client
    .request<DeleteUserApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("DeleteUserAsync error.", data.errors);
        throw new Error("Error deleting user.");
      }
      // Return payload.
      return data.isSuccess;
    });
};
